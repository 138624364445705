import './home.css';

// images

import creativity from '../../assets/creativity-new.svg'
import rightArrow from '../../assets/right-arrow.svg'
import web from '../../assets/web.png'
import app from '../../assets/app.png'
import ui from '../../assets/ui.png'
import content from '../../assets/content.png'
import photography from '../../assets/photography.png'
import web2 from '../../assets/web2.png'
import analytics from '../../assets/analytics.png'
import dev from '../../assets/web2.png'

import AOS from 'aos';
import "aos/dist/aos.css";  
import { useEffect, useState } from 'react';

function Home({showReachOut}) {

  const [curImg, setCurImg] = useState(0)
  const listOfImg = [
    web2,
    app,
    ui,
    content,
    photography,
    analytics,
    // dev
  ]

  const names = [
    'Web Development',
    'App Development',
    'UI UX Design',
    'Content Writing',
    'Photography',
    'Data Analysis',
  ]

  useEffect(() => {
  }, [])

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: true
  };

  return (
    <div data-aos="fade-up" data-aos-delay="100" className="home">
      <div className='homeIntro bg' style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center'}}>
        <div className='mainCard'>
          <div>
            <p>Creativit</p>
            <p style={{marginLeft: '25px'}} className='rotate-center'>y</p>
          </div>
          <p style={{textAlign: 'center', fontWeight: '400'}}>at your fingertips.</p>
        </div>
        <div className='playBtn'>
          <svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path 
              d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
          </svg>
        </div>
        <div className='mainCard introHeroEl'>
          <p style={{textAlign: 'center', fontWeight: '400'}}>Start, Build and Grow your Biz. </p>
        </div>
      </div>
      <br />
      <p className='headerSubText'>We help you create your brand, scale it and everything you can imagine. We believe in a simple funda. Make it work, Make it look good and make it scale. We offer a lot of services. Here goes a very short brief.  <br /> <br /> </p>
      <div className='servicesSlider'>
        <div className='servicesSliderInner'>
          <div className='serviceSliderEL bg'>
              <img src={listOfImg[0]}  className="homeImage" alt="" />
              <p className='homeIntroCarouselLabel'>{names[0]}</p>
              <p className='homeIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>
                We help you create the most efficient web applications for your product and encourage the possibility to acquire the most catchy and impactful one at a best price.This services includes web development of UI heavy applications, Dashboards, Portals, E-commerce, Digital Marketing Profiles, digital transformation among a plethora of other services as well.
                Cuzzzz…When it's yours itz ours !!😇
              </p>
          </div>
          <div className='serviceSliderEL bg'>
            <img src={listOfImg[2]} className="homeImage" alt="" />
            <p className='homeIntroCarouselLabel'>{names[2]}</p>
            <p className='homeIntroCarouselSubLabel' style={{marginBottom: 'auto'}}> We're prettttty awesome with 3 years of real time experience in the latest and greatest UI/UX technologies.
              While designing we always keep in mind about the technology, visual aesthetics and business objectives, bringing you fast-loading, low latency, impactful and high converting online experiences <br></br> <br></br>
              We feel that when UX doesn't consider All Users, it simply SUX Xd!
            </p>              
          </div>
          <div className='serviceSliderEL bg'>
            <img src={listOfImg[4]} className="homeImage" alt="" />
            <p className='homeIntroCarouselLabel'>{names[4]}</p>
            <p className='homeIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>We know memories are made in minds not in frames. But our minds are busy somewhere else. We give you a second chance to relive those moments. With good edits of course ( Don't worry 'bout those wrinkles ).</p>
          </div>
        </div>
        <div className='servicesSliderInner'>
          <div className='serviceSliderEL bg'>
              <img src={listOfImg[1]} className="homeImage" alt="" />
              <p className='homeIntroCarouselLabel'>{names[1]}</p>
              <p className='homeIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>Oibre offers experience in well designed , user friendly Application development which comes with great hands on with UI/UX , different architecture patterns and tech stacks for App development. We do IOS and Android And we are sure you are gonna get impressed with our high quality deliverables. C’mon get impressed xD.</p>
          </div>
          <div className='serviceSliderEL bg'>
            <img src={listOfImg[3]} className="homeImage" alt="" />
            <p className='homeIntroCarouselLabel'>{names[3]}</p>
            <p className='homeIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>
              Good first impressions are good for business and good content is good for good first impressions. 
              Oh wait! Are you impressed with our content yet ?
              If yes , just let us know.. We are just a click away to create your awesome content.</p>

          </div>
          <div className='serviceSliderEL bg'>
            <img src={listOfImg[5]} className="homeImage" alt="" />
            <p className='homeIntroCarouselLabel'>{names[5]}</p>
            <p className='homeIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nesciunt maxime numquam eveniet sint minus earum assumenda pariatur incidunt. Quibusdam dicta voluptas modi expedita laudantium, cupiditate dolorem quos ab eos. Quaerat!</p>
          </div>
        </div>
      </div>

      {/* <p className='headerSubText' style={{marginTop: '120px'}}> Still not sure what we do ? Cool !! We do Web development, App development, UI UX Design, Machine Learning, Data Analysis and Photography </p> */}
      <p className='headerSubText lastSubText'> Impressed ? Woohoo !! That's what we are aiming at. Now that we got you, Let's talk biz. You name it, We got it.</p>

      <div className='homeFooter bg'>
        <div className='homeFooterTextCont'>
          <p className='homeFooterText'>
            Products are the new meta
          </p>
          <p className='homeFooterSubText'>
            We have couple of products under the rugs. Click the button and get your mind blown 🤯🤯
          </p>
          <div onClick={() => showReachOut(true)} className='homeFooterBtn'>
            <p>Awesome !! Let's meet</p>
            <img src={rightArrow} alt="" />
          </div>
        </div>
        <div className='homeFooterImg'>
          <img src={creativity} alt="" />
        </div>
      </div>

      <div className='headerSubText lastSubText'> We are solving the most difficult problem in Technology Services space. <span className='nameBg'>The resourses. </span> We can talk about it for hours but we will make it simple. <br /> <br /> 
      We conduct bootcamps in colleges and for individuals. Train them in fullstack development and make them industry ready. We will upload a short clip explaining the strategy shortly.</div>

      <div className='bottonPaddingDiv'></div>

      {/* <img src={listOfImg[curImg % 6]}  className="homeImage" alt="" />
      <p className='homeIntroCarouselLabel'>{names[curImg % 6]}</p> */}


      {/* <div id="homeIntroImagesCarousel">
        <img src={web} className="homeImage"  />
        <img src={app} className="homeImage"  />
        <img src={ui} className="homeImage"  />
      </div> */}
    </div>
  );
}

export default Home;