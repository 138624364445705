import { Routes, Route, useNavigate } from "react-router-dom";

// imports

import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";  

// assets

import './App.css';
import './animations.css'
import logo from './assets/logo.svg'
import loading from './assets/loading.gif'
import menu from './assets/menu.png'

// icons

import homeIcon from './assets/home.svg'
import worksIcon from './assets/works.svg'
import teamIcon from './assets/team.svg'
import servicesIcon from './assets/services.svg'
import coursesIcon from './assets/courses2.svg'

// pages

import Home from './pages/home/home';
import Blog from './pages/blog/blog';
import Notfound from './pages/404/404';
import Works from './pages/works/works';
import Services from './pages/services/services';
import Team from './pages/team/team';
import WebDev from "./pages/courses/webDevBootcamp/webdev";
import Courses from "./pages/courses/index";
import Pd from './pages/pitch-deck/index'

// components 

import ReachOut from './components/reachout/reach'



function App() {

  const [curLocation, setCurLocation] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true)
  const [showRoutes, setShowRoutes] = useState(false)
  const [showReachout, setShowReachout] = useState(false)
  const routesRef = useRef()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNum, setPhoneNum] = useState('')
  const [msg, setMsg] = useState('')
  const [showSent, setShowSent] = useState('')


  // page load

  useEffect(() => {
    setCurLocation(location.pathname)
    AOS.init({
      duration : 1000
    });
    setTimeout(() => {
      setShowLoadingAnimation(false)
    }, 3000);
  }, [])

  // route change detection

  useEffect(() => {
    console.log(location.pathname)
    setCurLocation('/' + location.pathname.split('/')[1])
  }, [location])

  const showReachOutModal = (val) => {
    setShowReachout(val);
  };

  const setValuesFunc = (val) => {

    console.log(val)

    setName(val.name)
    setEmail(val.email)
    setPhoneNum(val.phoneNum)
    setMsg(val.msg)
    setShowSent(val.showSent)
  }

  return (
    
  showLoadingAnimation ? 
  
  // loaders

    <div className='loader loadingAnimation'>
      <img src={logo} className="" alt="" />
      <div>
        <p>O</p>
        <p>i</p>
        <p>b</p>
        <p>r</p>
        <p>e</p>
      </div>
    </div> :
        
  
  // content 

  <div className="App">
    
    { curLocation !== '' && 
      <div style={{opacity: showReachout ? '0.4' : '1', transition: '0.3s'}} data-aos="fade-down" data-aos-delay="100" className='header'>
        <div onClick={() => navigate('/')} style={{cursor: 'pointer'}} className='logo'>
          <img src={logo} alt="" />
          <p>Oibre</p>
        </div>
        {/* <p onClick={() => navigate('/')} id="home" className={`navItem ${curLocation == '/' ? 'showUnderline' : ''}`} style={{marginLeft: 'auto'}}>Home</p> */}
        <p onClick={() => navigate('/team')} id="team" className={`navItem ${curLocation == '/team' ? 'showUnderline' : ''}`}>Team</p>
        <p onClick={() => navigate('/works')} id="works" className={`navItem ${curLocation == '/works' ? 'showUnderline' : ''}`}>Works</p>
        <p onClick={() => navigate('/services')} id="services" className={`navItem ${curLocation == '/services' ? 'showUnderline' : ''}`}>Services</p>
        <p onClick={() => navigate('/courses')} id="courses" className={`navItem ${curLocation == '/courses' ? 'showUnderline' : ''}`}>Courses</p>
        {/* <p onClick={() => navigate('/blog')} className={`navItem ${curLocation == '/blog' ? 'showUnderline' : ''}`}>Blog</p> */}
        <div className='navItem_Contact_Cont'>
          <p onClick={() => {
            setShowReachout(true)
          }} className='navItem_Contact'>Reach <br /> out</p>
        </div>
        {/* <div className="hamburgerIcon">
          <img src={menu} className="hamburgerIcon_Img" alt="" />
        </div> */}
      </div>
    }

    {showReachout ? <ReachOut style={{zIndex: '999'}} values={{name, email, phoneNum, msg, showSent}} showReachOut={showReachOutModal} setValues={setValuesFunc} /> : <></>}

    <div style={{opacity: showReachout ? '1' : '1', transition: '0.3s'}} ref={routesRef} className="bottomNav">
      <div onClick={() => navigate('/team')} className={`bottomNavEl ${curLocation == '/team' ? 'bottomNavElBg' : ''}`}>
        <img src={teamIcon} alt="" />
        <p>Team</p>
      </div>
      <div onClick={() => navigate('/works')} className={`bottomNavEl ${curLocation == '/works' ? 'bottomNavElBg' : ''}`}>
        <img src={worksIcon} alt="" />
        <p>Works</p>
      </div>
      <div onClick={() => navigate('/services')} className={`bottomNavEl ${curLocation == '/services' ? 'bottomNavElBg' : ''}`}>
        <img src={servicesIcon} alt="" />
        <p>Services</p>
      </div>
      <div onClick={() => navigate('/courses')} className={`bottomNavEl ${curLocation == '/courses' ? 'bottomNavElBg' : ''}`}>
        <img src={coursesIcon} alt="" />
        <p>Courses</p>
      </div>
    </div>

    <div style={{opacity: showReachout ? '0.3' : '1', transition: '0.3s'}}>
      <div data-aos="fade-up" data-aos-delay="800">
        <Routes>
          <Route path='/' element={<Home showReachOut={showReachOutModal} />} />
          <Route path='/works' element={<Works showReachOut={showReachOutModal} />} />
          <Route path='/services' element={<Services showReachOut={showReachOutModal} />} />
          <Route path='/team' element={<Team showReachOut={showReachOutModal} />} />
          <Route path='/courses' element={<Courses showReachOut={showReachOutModal} />} /> 
          <Route path='/courses/web-dev' element={<WebDev />} />
          <Route path='/pitch-deck' element={<Pd />} />
          <Route path='*' element={<Notfound showReachOut={showReachOutModal} />} />
        </Routes>
      </div>
    </div>
  </div>
  );
}

export default App;
