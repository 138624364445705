import AOS from 'aos';
import "aos/dist/aos.css";  
import './works.css'
import '../../animations.css'

import worksHero from '../../assets/works.png'
import rightArrow from '../../assets/right-arrow.svg'

function Works({showReachOut}) {
  return (
    <div data-aos="fade-up" data-aos-delay="100" className="Works">
        <div className="worksIntro bg">
          <p className="introText">We have a lot <br /> to show you</p>
          <img src={worksHero} className="heroImg" alt="" />
          <p className="introText">We are trying <br /> to give you the best experience</p>
        </div>
        <p className="introSubText introSubText__works">Our team super talented sorcerers are whiping up this page lightspeed. Please bear with us. Should be out within couple of days. <br /><br /> Please have a look at our services or drop us a message and we will be more than happy to give all the information.</p>
        <div className="worksContainer"> 
          <div className="worksItem">
            <img src="" alt="" />
            <div>
              <p></p>
              <div onClick={() => showReachOut(true)} className='homeFooterBtn worksBtn'>
                <p>Awesome !! Let's Go</p>
                <img style={{marginLeft: '30px'}} src={rightArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className='bottonPaddingDiv'></div>
    </div>
  );
}

export default Works;