import './reach.css'
import axios from 'axios'
import {useState} from 'react'
import loadingGif from '../../assets/loading3.gif'
import revv from '../../assets/revv.png'

const imgData = `
  data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAzNJREFUaEPNWtFx5SAMXCq4KyElXAmXTtzRpYPXUjrIpIOXCrjhYWMshLTw7En8lXkWQiutVuBJgPIEAFF7cdJvrf/5HdNKQF3POGVsTkIt3axbZwCnPwLYRTiT24sAtBm5CMMAgBMjGHWV7QMQYtOcogKM69qGsZ/g54Bbg0I64olw+jox5ExHpQJgEuDauAa96AthspQ7foYo5Or3uUUrCK19f7gKiWp0hu4QE7/VWKHT6RUYov6QsZ46F4Czx28Ad7sdxdvisPxh+thXT6pQj4UBWCLwD8ArgHePWu30ePyywPThl0hVIX8Z0sa3NehUAQqEAFn5CHeE+IroJ0I703nJy+93VC8APsSPNog2/Tn4Y6Y+ASTfQ09S7v7pv/cqYEEsFdg2vCOAyeKe+d3/F4C/LRV9LvBN3PqqabSDsOmkrekEzxXCBeC4eQQksGU6BbyLa93pwa/M9su0gehYMpW4JPitNask82BaRQm3w2E94I74UKc/lWJty4ZoY0WVm1i5KGjUaR0dpkSvEmlQ1c9Q8F4nuD3g1uQokZo61SpMB98qbw1lf/s8gDZFWiWSFRm8m7LDCMoA3DXMrCg22pBKu0xMbHvfxHyjAi4qjZ697OtzwuaJR/+S+2MR+J6WG/Sk8pcwVCpBJksxMyhEOs3RWTqvyWhv2B343ZRAB1CnXFhwGJYA3MS3VNmwzLCjKCMR8k2szwpzwgr8J4I4yKggPZf1lTb19H0s9KRyQcBN5GJCnfZiuXOgQ0olm+ELiMyRWK8EdxTX2qJ3QNAn33rp+BCrvMwzivUZgJetl4p/hxFkBUQdApYQS+OOBr+CCQsQt2tp5WNMx4cGmdKUb/pNihaUpGBvMd3G2vsD5UQAGEMPgPwkYsZi+3C4RFKIBOaOEV7iepBrPN2zELMN22THQIzDjwHeisf+KkGxkDPKQdipsZOiq6UAwOSeCdjItFhui7jvh+wBJug5G7ImrfN14QSAJ6s01zzd7LQAnoxvqA4uQ9rOlmfKAuDSuFXn5+w4QSFxh+aV0W3foeptB5KZRSYhL/gvEWcO8BDcok806LPsmqNQzSIXlZEgd61rgP+Q70pEy5TI5AAAAABJRU5ErkJggg==
`

const ReachOut = ({showReachOut, values, setValues}) => {

  console.log(values)

  const [name, setName] = useState(values.name)
  const [email, setEmail] = useState(values.email)
  const [phoneNum, setPhoneNum] = useState(values.phoneNum)
  const [msg, setMsg] = useState(values.msg)

  const [showSent, setShowSent] = useState(values.showSent)
  const [loading, setLoading] = useState(false)

  const sendMsg = () => {
    setLoading(true)
    axios.get(`https://vamsioibre.pythonanywhere.com/?name=${name}&phone=${phoneNum}&email=${email}&msg=${msg}`)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    }).finally(() => {
      setShowSent(true)
      setLoading(false)
    })
  }

  return (
    <div className="reachOutOuterCont">
      {!showSent ? 
        <div className='reachOutCont'>
          <div>
            {/* <img className='cross' onClick={() => showReachOut(false)} src={imgData} /> */}
            <p className='reachOutHeading'>Reach Out</p>
            <p className='reachOutSubHeading'>We are really great at listening. Try us. Tell us your story and we just might have a solution for you. Also, we work out of India. So we usually work on IST business timings. Precisely 24/7.</p>
            <input onChange={(e) => {setName(e.target.value)}} value={name} className='input' placeholder='Name' type="text" name="" id="" />
            <input onChange={(e) => {setEmail(e.target.value)}} value={email} className='input' placeholder='Email'  type="text" name="" id="" />
            <input onChange={(e) => {setPhoneNum(e.target.value)}} value={phoneNum} className='input' placeholder='Contact Number'  type="text" name="" id="" />
            <textarea onChange={(e) => {setMsg(e.target.value)}} value={msg} className='input' placeholder='Message'  name="" id="" cols="30" rows="10"></textarea>
            <button onClick={() => {
              if(name && email && phoneNum && msg) {
                sendMsg()
              } else {
                alert('Please fill all the details')
              }
            }} className='btn btnPrimary btnFlex' style={{marginTop: '0px'}}>
                {!loading ? <p>Send !!</p> : <img src={loadingGif} style={{width: '20px', height: 'auto'}} />}
              </button>     
            <button onClick={() => {showReachOut(false); setValues({
            name, email, phoneNum, msg, showSent
          })}} className='btn'>Close</button>   
          </div>  
        </div> : 
        
        <div className='reachOutCont'>
          <p className='reachOutHeading' >Message Sent !!!</p>
          <p className='reachOutSubHeading'>Your message has been sent to Revv, out support bot. He will take care of it and redirect it to the right person. Our team will get back to you within no time with a perfect solution / answer for you. In the mean time, please have a look at your works and services. </p>
          <img src={revv} style={{width: '50%', height: 'auto', marginTop: '20px',  marginLeft: '30%',  marginRight: 'auto',  marginBottom: '60px'}} alt="" />
          <button onClick={() => {showReachOut(false); setValues({
            name, email, phoneNum, msg, showSent
          })}} className='btn'>Close</button>   
        </div>
      }
    </div>
  )
}

export default ReachOut