import AOS from 'aos';
import "aos/dist/aos.css";  
import './services.css'
import '../../animations.css'

import {useState} from 'react'
import {useNavigate } from "react-router-dom";

import servicesHero from '../../assets/services.png'
import rightArrow from '../../assets/right-arrow.svg'
import web from '../../assets/web.png'
import app from '../../assets/app.png'
import ui from '../../assets/ui.png'
import content from '../../assets/content.png'
import photography from '../../assets/photography.png'
import web2 from '../../assets/web2.png'
import analytics from '../../assets/analytics.png'
import dev from '../../assets/web.png'


function Services({showReachOut}) {

  const [curImg, setCurImg] = useState(0)
  const listOfImg = [
    web2,
    app,
    ui,
    content,
    photography,
    analytics,
    dev
  ]

  const navigate = useNavigate()

  const names = [
    'Web Development',
    'App Development',
    'UI UX Design',
    'Content Writing',
    'Photography',
    'Data Analysis',
  ]

  return (
    <div data-aos="fade-up" data-aos-delay="100" className="Works">
        <div className="worksIntro bg">
          <p className="introText">We craft our <br /> services with love</p>
          <img src={servicesHero} className="heroImg" alt="" />
          <p className="introText">Do take a look at <br />  the projects we have taken up</p>
        </div>
        <p className="introSubText introSubText__works">We are the A listers in the services we offer. We promise you we won't dilute the mix with the huge list of things we can / might do. You have any services other than the listed ones, Please drop us a message and we will always help you out. Our guys are super helpful, we are sure we will get you a solution to what you we are facing. </p>
        <div className="worksContainer">
          <div className="worksItem">
            <img src="" alt="" />
            <div>
              <div onClick={() => showReachOut(true)} className='homeFooterBtn worksBtn'>
                <p>Awesome !! Let's meet</p>
                <img style={{marginLeft: '30px'}} src={rightArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className='servicesSlider'>
          <div className='servicesSliderInner'>
            <div className='serviceSliderEL bg'>
                <img src={listOfImg[0]}  className="homeImage" alt="" />
                <p className='homeIntroCarouselLabel servicesIntroCarouselLabel'>{names[0]}</p>
                <p className='homeIntroCarouselSubLabel servicesIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>
                  We help you create the most efficient web applications for your product and encourage the possibility to acquire the most catchy and impactful one at a best price.This services includes web development of UI heavy applications, Dashboards, Portals, E-commerce, Digital Marketing Profiles, digital transformation among a plethora of other services as well.
                  Cuzzzz…When it's yours itz ours !!😇
                </p>
            </div>
            <div className='serviceSliderEL bg'>
              <img src={listOfImg[2]} className="homeImage" alt="" />
              <p className='homeIntroCarouselLabel servicesIntroCarouselLabel'>{names[2]}</p>
              <p className='homeIntroCarouselSubLabel servicesIntroCarouselSubLabel' style={{marginBottom: 'auto'}}> We're prettttty awesome with 3 years of real time experience in the latest and greatest UI/UX technologies.
                While designing we always keep in mind about the technology, visual aesthetics and business objectives, bringing you fast-loading, low latency, impactful and high converting online experiences <br></br> <br></br>
                We feel that when UX doesn't consider All Users, it simply SUX Xd!
              </p>              
            </div>
            <div className='serviceSliderEL bg'>
              <img src={listOfImg[4]} className="homeImage" alt="" />
              <p className='homeIntroCarouselLabel servicesIntroCarouselLabel'>{names[4]}</p>
              <p className='homeIntroCarouselSubLabel servicesIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>We know memories are made in minds not in frames. But our minds are busy somewhere else. We give you a second chance to relive those moments. With good edits of course ( Don't worry 'bout those wrinkles ).</p>
            </div>
          </div>
          <div className='servicesSliderInner'>
            <div className='serviceSliderEL bg'>
                <img src={listOfImg[1]} className="homeImage" alt="" />
                <p className='homeIntroCarouselLabel servicesIntroCarouselLabel'>{names[1]}</p>
                <p className='homeIntroCarouselSubLabel servicesIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>Oibre offers experience in well designed , user friendly Application development which comes with great hands on with UI/UX , different architecture patterns and tech stacks for App development. We do IOS and Android And we are sure you are gonna get impressed with our high quality deliverables. C’mon get impressed xD.</p>
            </div>
            <div className='serviceSliderEL bg'>
              <img src={listOfImg[3]} className="homeImage" alt="" />
              <p className='homeIntroCarouselLabel servicesIntroCarouselLabel'>{names[3]}</p>
              <p className='homeIntroCarouselSubLabel servicesIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>
                Good first impressions are good for business and good content is good for good first impressions. 
                Oh wait! Are you impressed with our content yet ?
                If yes , just let us know.. We are just a click away to create your awesome content.</p>
            </div>
            <div className='serviceSliderEL bg'>
              <img src={listOfImg[5]} className="homeImage" alt="" />
              <p className='homeIntroCarouselLabel servicesIntroCarouselLabel'>{names[5]}</p>
              <p className='homeIntroCarouselSubLabel servicesIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>
                The age of internet has risen. Blessed the computing Gods, we have a huge pile of data that is just lying there dormant. Our team of expert wizards can go through that data and magically give you the insights on it that can be understood by a todler.  
              </p>
            </div>
            <div onClick={() => {
              navigate('/courses/web-dev')
            }} style={{cursor: 'pointer'}} className='serviceSliderEL bg'>
              <img src={listOfImg[6]} className="homeImage" alt="" />
              <p className='homeIntroCarouselLabel servicesIntroCarouselLabel'>Web Dev Bootcamp</p>
              <p className='homeIntroCarouselSubLabel servicesIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>
                We aim to build a community of thinkers and doers. Now is the chance for a thinker to be a doer. Code away and have fun !!              </p>
            </div>
          </div>
        </div>
        <div className="introSubText introSubText__works">
          Still confused on what to we do or how we can help your business grow ? Worry not. Just drop us a message and we will instantly be there for your assistance. Aight, too much stuff to fill in ? You can directly reach out to us on our mobile <span className='nameBg'> +91 9059678813</span> or you drop a message on whatsapp on the same number. 
        </div>
        <div onClick={() => showReachOut(true)} className='homeFooterBtn worksBtn'>
          <p>Drop a message</p>
          <img style={{marginLeft: '30px'}} src={rightArrow} alt="" />
        </div>
        <div className='bottonPaddingDiv'></div>
    </div>
  );
}

export default Services;