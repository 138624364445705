import AOS from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react'
import Accordion from 'react-accordian'
import dev from '../../../assets/web.png'
import '../../team/team.css'
import '../../home/home.css'
import './webdev.css'

import { useState } from 'react';

const WebDev = ({showReachOut}) => {

  useEffect(() => {
    AOS.init({
      // initialise with other settings
      duration : 1000
    });
  }, [])

  const [clicked, setClicked] = useState('0')
  const handleToggle = (index) => {
    if (clicked === index) return setClicked('0')
    setClicked(index)
  }

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    // fetch('https://firebasestorage.googleapis.com/v0/b/oibre-landing.appspot.com/o/Full%20stack%20web%20developement%20-%20Course%20Plan-2.pdf?alt=media&token=6eb8efbb-d930-4b17-b647-b686aba026b3').then(response => {
    //     response.blob().then(blob => {
    //         // Creating new object of PDF file
    //         const fileURL = window.URL.createObjectURL(blob);
    //         // Setting various property values
    //         let alink = document.createElement('a');
    //         alink.href = fileURL;
    //         alink.download = 'Full stack web developement - Course Plan.pdf';
    //         alink.click();
    //     })
    // })
    window.open('https://firebasestorage.googleapis.com/v0/b/oibre-landing.appspot.com/o/Full%20stack%20web%20developement%20-%20Course%20Plan-2.pdf?alt=media&token=6eb8efbb-d930-4b17-b647-b686aba026b3', '_blank', )
  }

  const goToPayment = (e) => {
    window.open(e, '_blank', 'noreferrer')
  }

  return (
    <div className="Works coursesCont">
      <div data-aos="fade-up" className='teamIntro coursesIntroCont bg'>
        <div className='teamIntroTextCont'>
          <p className="teamIntroText slide-top-fade">
            Web Dev Bootcamp
          </p>
          <p className='teamIntroSubText'>
            We aim to build a community of thinkers and doers. Now is the chance for a thinker to be a doer. Code away and have fun !!
            <br /><br />
            <a className='nameBg' style={{cursor: 'pointer'}} href="https://forms.gle/788GQQfPjT1e6Ztw8"> https://forms.gle/788GQQfPjT1e6Ztw8 </a> <br /> <br />
          </p>
        </div>
        <div className="teamIntroImg">
          <img src={dev} className="homeImage coursesIntroImg" alt="" />
        </div>
      </div>

      <div className='introTextCont'>
        <p data-aos="fade-up" className='introText'>Course Curricullum <br /> and Timelines</p>
        <div data-aos="fade-up" className='introSubText'>
          <Accordion
            active={clicked === 0}
            onToggle={() => handleToggle(0)}
            header="Week 1"
            gap="8px"
            styling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
            activeStyling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
          >
            <div style={{textAlign: 'left', fontSize: '20px', background: 'transparent', border: '3px solid #eee', padding: '40px', marginTop: '0px', height: 'auto'}}>
              1. Working of the web <br />
              2. HTML <br />
              &nbsp; &nbsp; Tags <br />
              &nbsp; &nbsp; Attributes<br />
              &nbsp; &nbsp; Creating layouts <br />
              3. CSS <br />
              &nbsp; &nbsp; Basic rules <br />
              &nbsp; &nbsp; Margin and padding <br />
              &nbsp; &nbsp; Positions  <br />
              &nbsp; &nbsp; Floats <br />
              &nbsp; &nbsp; Flex box <br />
              &nbsp; &nbsp; Grid <br />
              &nbsp; &nbsp; Responsivenss <br />
              4. Javascript <br />
              &nbsp; &nbsp; Basics <br />
              &nbsp; &nbsp; Variables  <br />
              &nbsp; &nbsp; Let Var Const <br />
              &nbsp; &nbsp; Hoisting <br />
              &nbsp; &nbsp; Logging and Console <br />
              &nbsp; &nbsp; Functions <br />
              &nbsp; &nbsp; Conditionals <br />
              &nbsp; &nbsp; Logical Operators <br />
              &nbsp; &nbsp; Scope <br />
              &nbsp; &nbsp; DOM <br />
              &nbsp; &nbsp; Events <br /> <br />


            </div>
          </Accordion>
          <Accordion
            active={clicked === 1}
            onToggle={() => handleToggle(1)}
            header="Week 2"
            gap="8px"
            styling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
            activeStyling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
          >
            <div style={{textAlign: 'left', fontSize: '20px', background: 'transparent', border: '3px solid #eee', padding: '40px', marginTop: '0px'}}>
            1. CSS Animations <br />
            &nbsp; &nbsp; Transitions <br />
            &nbsp; &nbsp; Keyframes <br />

            2. Advanced Javascript <br />
            &nbsp; &nbsp; Callbacks <br />
            &nbsp; &nbsp; Promises <br />
            &nbsp; &nbsp; Async / Await <br />
            &nbsp; &nbsp; Fetch <br />
            &nbsp; &nbsp; Axios <br />
            &nbsp; &nbsp; Browser Storage <br />
            &nbsp; &nbsp; Closures <br />
            &nbsp; &nbsp; Prototypes <br />
            &nbsp; &nbsp; Decorators <br />
            &nbsp; &nbsp; Currying <br />
            &nbsp; &nbsp; Shadow and Deep copy <br />
            &nbsp; &nbsp; Throttling and Debouncing <br />
            &nbsp; &nbsp; Momoisation <br />

            </div>
          </Accordion>
          <Accordion
            active={clicked === 2}
            onToggle={() => handleToggle(2)}
            header="Week 3"
            gap="8px"
            styling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
            activeStyling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
          >
            <div style={{textAlign: 'left', fontSize: '20px', background: 'transparent', border: '3px solid #eee', padding: '40px', marginTop: '0px'}}>
              React <br />
              &nbsp; &nbsp; Basics <br />
              &nbsp; &nbsp; JSX <br />
              &nbsp; &nbsp; Virtual DOM  <br />
              &nbsp; &nbsp; Router <br />
              &nbsp; &nbsp; Component Life Cycle <br />
              &nbsp; &nbsp; Refs <br />
              &nbsp; &nbsp; State <br />
              &nbsp; &nbsp; Props <br />
              &nbsp; &nbsp; Hooks <br />
              &nbsp; &nbsp; Writing Custom hooks <br />
              &nbsp; &nbsp; Lifecycle methods with hooks <br />
              &nbsp; &nbsp; Context <br />
              &nbsp; &nbsp; Companion Libraries <br />

            </div>
          </Accordion>
          <Accordion
            active={clicked === 3}
            onToggle={() => handleToggle(3)}
            header="Week 4"
            gap="8px"
            styling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
            activeStyling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
          >
            <div style={{textAlign: 'left', fontSize: '20px', background: 'transparent', border: '3px solid #eee', padding: '40px', marginTop: '0px'}}>
              This is a project week. You will be given a capstone project. We will have 3 sessions this week where we help you build your application and resolve any issues you are facing. This is last week for the Frontend Leg of the bootcamp.
            </div>
          </Accordion>
          <Accordion
            active={clicked === 4}
            onToggle={() => handleToggle(4)}
            header="Week 5"
            gap="8px"
            styling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
            activeStyling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
          >
            <div style={{textAlign: 'left', fontSize: '20px', background: 'transparent', border: '3px solid #eee', padding: '40px', marginTop: '0px'}}>
              1. Understanding Backend Architecture <br />
              2. Architectural Paradigms <br />
              3. Choosing an architecture <br />
              4. Node Js <br />
              5. Creating a REST API <br />
              6. Communication with frontend <br />
              7. APIs and Endpoints <br />
              8. Modules and Sub routines <br />
            </div>
          </Accordion>
          <Accordion
            active={clicked === 5}
            onToggle={() => handleToggle(5)}
            header="Week 6"
            gap="8px"
            styling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
            activeStyling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
          >
            <div style={{textAlign: 'left', fontSize: '20px', background: 'transparent', border: '3px solid #eee', padding: '40px', marginTop: '0px'}}>
              1. Databases <br />
              2. Choosing a DB <br />
              3. SQL vs NoSQL <br />
              4. Different DB options<br />
              5. Introduction to MongDB <br />
              6. Documents and Collections <br />
              7. Connecting DB and Backend <br />
              8. CRUD operations on DB <br />
            </div>
          </Accordion>
          <Accordion
            active={clicked === 6}
            onToggle={() => handleToggle(6)}
            header="Week 7"
            gap="8px"
            styling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
            activeStyling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
          >
            <div style={{textAlign: 'left', fontSize: '20px', background: 'transparent', border: '3px solid #eee', padding: '40px', marginTop: '0px'}}>
              1. Build Authentication Module <br />
              2. Build Blogs Module <br />
              3. Build File Directory Module <br />
            </div>
          </Accordion>
          <Accordion
            active={clicked === 7}
            onToggle={() => handleToggle(7)}
            header="Week 8"
            gap="8px"
            styling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
            activeStyling={{padding: '15px 40px', cursor: 'pointer', fontFamily: 'Roboto Mono', fontSize: '20px'}}
          >
            <div style={{textAlign: 'left', fontSize: '20px', background: 'transparent', border: '3px solid #eee', padding: '40px', marginTop: '0px'}}>
              1. Intro to Redis <br />
              2. Intro to GraphQL <br />
              3. Degugging and project extension <br />
            </div>
          </Accordion>
        </div>
      </div>

      <div className='introTextCont'>
        <p data-aos="fade-up" className='introText'>Pricing and <br /> Payment Options</p>
        <div data-aos="fade-up" data-aos-delay="150" className='introSubText'>
          We are pricing this at a very reasonable amount. We don't want to burden you guys with a lot of load. The whole bootcamp is divided into two parts. The frontend section and the backend section. <br /> <br />
          We charge <span className='nameBg'>Rs. 4000/- </span> for the frontend section. And an additional <span className='nameBg'>Rs. 7000/- </span> for the backend section. 
          You don't have to register for the full course. You can try the bootcamp with the frontend session and choose backend session if needed. If you want to pay for the entire bootcamp, you just need to pay <span className='nameBg'>Rs. 10,000/- </span>. 
        </div>

        {/* pricing cards */}

        <div className='profileTabs' style={{marginTop: '100px'}}>
          <div data-aos-delay="150" data-aos="fade-right" data-aos-duration="1500" className='profileTab'>
            <div className='profileImg'>
            </div>
            <p className='name'>Frontend Bootcamp</p>
            <div className='description'>
              This is a part of the Fullstack Web Dev Bootcamp. You will learn frontend technologies like HTML, CSS, JS and React. 
              This is a 3 week program and the pricing for it is <span className='nameBg'>Rs. 4,000</span>
            </div>
            <div style={{marginTop: '10px'}} onClick={() => goToPayment('https://buy.stripe.com/bIY2a842lblu1z27st')} className='homeFooterBtn'>
              <p>Join Frontend</p>
            </div>
          </div>
          <div data-aos-delay="150" data-aos="fade-right" data-aos-duration="1500" className='profileTab'>
            <div className='profileImg'>
            </div>
            <p className='name'>Fullstack Bootcamp</p>
            <div className='description'>
              This is an in-deapth fullstack learning program where you will get to learn Frontend Technologies like HTML, CSS, JS, React 
              and Backend Technologies like NodeJs, MongoDB. You will get to build multiple projects with your team.
              This is an 8 week program and the pricing for it is <span className='nameBg'>Rs. 10,000</span>
            </div>
            <div style={{marginTop: '10px'}} onClick={() => goToPayment('https://buy.stripe.com/bIYg0YgP7blu91u5kk')} className='homeFooterBtn'>
              <p>Join Fullstack</p>
            </div>
          </div>
        </div>

      </div>

      <div data-aos="fade-up" className='introTextCont'>
        <p className='introText'>Additional <br /> Information</p>
        <div className='introSubText'>
          So !! The payment portal is under construction now. Please drop a message to <span className='nameBg'>+91 8106210367 </span> or
          <span className='nameBg'> +91 9059678813. </span> Please fill the form below so that we can understand your requirements.  <br /> <br />
          <a className='nameBg' style={{cursor: 'pointer'}} href="https://forms.gle/788GQQfPjT1e6Ztw8"> https://forms.gle/788GQQfPjT1e6Ztw8 </a> <br /> <br />
          Wanna know more about the course curriculum, you can find the detailed course overview and the projects you take up <a style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={onButtonClick} className='nameBg'>here</a>
        </div>
      </div>

      <div className='bottonPaddingDiv'></div>

    </div>
  )
}

export default WebDev