// imports

import AOS from 'aos';
import "aos/dist/aos.css";  
import { useEffect, useState } from 'react';
import '../../home/home.css';
import './index.css'
import { useNavigate } from "react-router-dom";


import creativity from '../../../assets/creativity-new.svg'
import rightArrow from '../../../assets/right-arrow.svg'
import web from '../../../assets/web.png'
import app from '../../../assets/app.png'
import ui from '../../../assets/ui.png'
import content from '../../../assets/content.png'
import photography from '../../../assets/photography.png'
import web2 from '../../../assets/web2.png'
import analytics from '../../../assets/analytics.png'
import dev from '../../../assets/web.png'
import learning from '../../../assets/learning2.png'

const Courses = ({showReachOut}) => {

  const [curImg, setCurImg] = useState(0)
  const listOfImg = [
    dev
  ]

  const names = [
    'Web Development Bootcamp',
  ]

  useEffect(() => {
  }, [])

  const navigate = useNavigate()

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: true
  };

  return (
    <div data-aos="fade-up" data-aos-delay="100" className="home">
      <div className='homeIntro bg' style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center'}}>
        <div className='mainCard'>
          <p style={{textAlign: 'center', fontWeight: '400'}}>Learning is a journey !!</p>
        </div>
        <img className='coursesHeroImg' src={learning} alt="" srcset="" />
        <div className='mainCard introHeroEl'>
          <p style={{textAlign: 'center', fontWeight: '400'}}>All of us students, so lets learn. </p>
        </div>
      </div>
      <br />
      <p className='headerSubText'>We know starting a biz is difficult and finding funds to get techincal help is even more stressfull. Fret not !! We are here to help you out.  <br /> <br /> You are a student ? Ooh !! Now is the time to learn some skills. Hop on board and cruise the knowledge river !! Uuum, that's dramatic. But you can learn a lot of stuff. <br /> <br /> Just click on the course you want to learn. <br /> <br /> </p>
      <div className='servicesSlider'>
        <div className='servicesSliderInner'>
          <div style={{cursor: 'pointer'}} onClick={() => {
            navigate('/courses/web-dev')
          }} className='serviceSliderEL scaleOnHover bg'>
              <img src={listOfImg[0]}  className="homeImage" alt="" />
              <p className='homeIntroCarouselLabel'>{names[0]}</p>
              <p className='homeIntroCarouselSubLabel' style={{marginBottom: 'auto'}}>
                We help you create the most efficient web applications for your product and encourage the possibility to acquire the most catchy and impactful one at a best price.This services includes web development of UI heavy applications, Dashboards, Portals, E-commerce, Digital Marketing Profiles, digital transformation among a plethora of other services as well.
                Cuzzzz…When it's yours itz ours !!😇
              </p>
          </div>
        </div>
        {/* <div className='servicesSliderInner'>
        </div> */}
      </div>

      {/* <p className='headerSubText' style={{marginTop: '120px'}}> Still not sure what we do ? Cool !! We do Web development, App development, UI UX Design, Machine Learning, Data Analysis and Photography </p> */}
      <p className='headerSubText lastSubText'> Impressed ? Woohoo !! We've a lot more things coming your way. So just hold on to your seats and enjoy the learning</p>

      <div className='bottonPaddingDiv'></div>

      {/* <img src={listOfImg[curImg % 6]}  className="homeImage" alt="" />
      <p className='homeIntroCarouselLabel'>{names[curImg % 6]}</p> */}


      {/* <div id="homeIntroImagesCarousel">
        <img src={web} className="homeImage"  />
        <img src={app} className="homeImage"  />
        <img src={ui} className="homeImage"  />
      </div> */}
    </div>
  )
}

export default Courses