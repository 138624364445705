// assets

import './team.css'
import '../../animations.css'
import teamIntroImg from '../../assets/teamIntro2.png'
import rachana from '../../assets/rachana3.png'
import vinay from '../../assets/vinay2.png'
import vamsi from '../../assets/vamsi2.png'
import brain from '../../assets/brain.png'
import revv from '../../assets/revv.png'

// imports

import ReactPlayer from 'react-player'
import AOS from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react'

function Team() {

  useEffect(() => {
    AOS.init({
      // initialise with other settings
      duration : 1000
    });
  }, [])

  return (
    <div className="Team">
      <div data-aos="fade-up" className='teamIntro bg'>
        <div className='teamIntroTextCont'>
          <p className="teamIntroText slide-top-fade">
            It's <br /> Possible <br /> with our team
          </p>
          <p className='teamIntroSubText'>
            We understand team is the key to success. So we chose a team that crack pratically every problem on earth. <br /> <br />
            PS: We solved Time travel, Quantum Gravity and Riemann hypothesis. 🤫🤫
          </p>
        </div>
        <div className="teamIntroImg">
          <img src={teamIntroImg} className="slide-left-fade" alt="" />
        </div>
      </div>

      <div className='introTextCont'>
        <p data-aos="fade-up" className='introText'>People who made <br /> this possible</p>
        <p data-aos="fade-up" data-aos-delay="150" className='introSubText'>
          These guys practically made this whole thing possible. 
          We have people with experience in different fields of 
          engeneering, development, photography, marketing and sales, creative work  and content writing. 
          With a never ending thurst to drench in the spirits of success, blah blah blah. 
          
          <br /><br /> 
          
          Click on the profiles to see more. Also, these guys are amazing at what they do. 
          Please do visit their portfolios.

          <br /><br />

          PS: Only Godly beings can talk to these sorcerers, just like you.
        </p>
      </div>

      <div className='profileTabs'>

        {/* vamsi */}

        <div data-aos-delay="150" data-aos="fade-right" data-aos-duration="1500" className='profileTab vamsi'>
          <div className='profileImg'>
            <img src={vamsi} alt="" />
            <div className='bgCircle_vamsi'></div>
          </div>
          <p className='name'>Vamsi Gottipati</p>
          <p className='description'>
            Hey !! I'm just a guy who builds things and break couple of them. Started couple of ventures. 
            Failed at most of them. Started Oibre with my buddies with a lot of love and sweat. 
            Been a Full stack developer for couple of years and teacher by passion.
          </p>
        </div>

        {/* rachana */}

        <div data-aos-delay="150" data-aos-duration="1500" data-aos="fade-up" className='profileTab rachana'>
          <div className='profileImg'>
            <img src={rachana} alt="" />
            <div className='bgCircle_rachana'></div>
          </div>
          <p className='name'>Rachana Venkumahanti</p>
          <p className='description'>
          Hey !! It was so boring for me to just sit in a classroom listening. Started 
          putting effort in Singing, Dancing, Development and Modelling. 
          Bringing a pinch of creativeness to this team of sorcerers.
          Taking care of customer relations @Oibre
          </p>
        </div>

        {/* vinay */}

        <div data-aos-delay="150" data-aos-duration="1500" data-aos="fade-left" className='profileTab vinay'>
          <div className='profileImg'>
            <img src={vinay} alt="" />
            <div className='bgCircle_vinay'></div>
          </div>
          <p className='name'>Vinay Jampana</p>
          <p className='description'>
            Hola !! You need development, you talk to me. My team worries about bringing in stuff and I just build them. 
            Been a full stack developer for about 4 years. Big CS Go freak and a full time comic MCU man. Go awy DCEU !
          </p>
        </div>

      </div>

      <div className='introTextCont'>
        <p data-aos="fade-up" className='introText'>Our support <br /> cogs and engines</p>
        <p data-aos="fade-up" data-aos-delay="150" className='introSubText'>
          Oibre is not just a bunch of people, we have an amazing combination of other resource that are essential for the functioning. We make sure they are credited when ever we can.
        </p>
      </div>

      <div data-aos="fade-up" className='profileTabs'>

        {/* revv */}

        <div className='profileTab revv'>
          <div className='profileImg'>
            <img src={revv} className="revvImg" alt="" />
            <div className='bgCircle_revv'></div>
          </div>
          <p className='name'>Revv</p>
          <p className='description'>
          01001000 01100101 01111001 00100000 00100001 00100001 00100000 01001001 00100111 01101101 00100000 01110100 01101000 01100101 00100000 01110011 01110101 01110000 01110000 01101111 01110010 01110100 00100000 01100010 01101111 01110100 00100000 01101111 01100110 00100000 01001111 01101001 01100010 01110010 01100101 00101110
            <br /> <br />
            Don't understand this ? <a target="_blank" href="https://pastebin.pl/view/raw/de9be86d">Click Here</a>
          </p>
        </div>

        {/* brain */}

        <div className='profileTab brain'>
          <div className='profileImg'>
            <img src={brain} className="brainImg" alt="" />
            <div className='bgCircle_brain'></div>
          </div>
          <p className='name'>Our braiiiin</p>
          <p className='description'>
            Our super creative brains need a daily wash with 4 cups of coffee and 3 liters of water.
            To keep it super smooth, we also have a movie session once a week. These ultimate power munching
            machines are super sensitive and receptive. You've got something to say, please... These puppies are waiting 
          </p>
        </div>

      </div>

      <div data-aos="fade-up" className='introTextCont'>
        <p className='introText'>Words of <br /> Wisdom ??</p>
        <p className='introSubText'>Naah, Just some random words from the founders and developers. Please do watch it. Can be super fun and we might get some leads &gt;_&lt; </p>
      </div>

      <div data-aos="fade-up" className='videoContainer'>
        <ReactPlayer width="100%" style={{ borderRadius: '20px' }} height="60vh" className="videoPlayer" url='https://www.youtube.com/watch?v=J3rf1jr521o' />
      </div>

      <div className='bottonPaddingDiv'></div>

    </div>
  );
}

export default Team;
