
function Notfound() {
    return (
      <div className="Home">
        404
      </div>
    );
  }
  
  export default Notfound;
  